<template>
  <div style="background: #f2f2f3">
    <van-nav-bar title="海报下载" left-arrow @click-left="$router.go(-1)" />
    <!-- <img class="poster-img" :src="imgPath" alt="" /> -->
    <van-image class="poster-img" :src="imgPath">
      <template v-slot:loading>
        <van-loading type="spinner" vertical color="#1989fa" size="24"
          >加载中...</van-loading
        >
      </template>
    </van-image>
    <div class="long-press-prompt">*（长按保存图片）</div>
    <div class="content">
      <h2>结算规则</h2>
      <p>
        1.海报会自动生成专属您的二维码，通过此二维码可以使得与用户建立绑定关系。
      </p>
      <p>
        2.用户通过二维码下载1号奢仓APP，并成功开通会员后，系统会反馈您奢粉账户对应佣金(即月卡会员，返58佣金；季卡会员返88佣金；年卡会员返188佣金)
      </p>
      <p>3.同一用户仅首次开通会员，才会有佣金报酬。后期续费无佣金提成。</p>
      <h2>下载提醒</h2>
      <p>1.如需用此海报进行线下推广的。请打印A4（210*297mm）大小的宣传尺寸。</p>
      <p>2.给印刷店打印时记得发送原图，并选择彩色打印。</p>
    </div>
  </div>
</template>

<script>
import { download } from "@/api/downloadImg";
export default {
  data() {
    return {
      imgPath: ""
    };
  },
  created() {
    this.downloadImg();
  },
  methods: {
    downloadImg() {
      download().then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.imgPath = res.data.data.imgPath;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
.poster-img {
  display: block;
  margin: auto;
  margin-top: 30px;
  width: 690px;
  height: 976px;
  box-shadow: 1px 3px 19px 0px rgba(79, 79, 79, 0.31);
}
.long-press-prompt {
  line-height: 26px;
  font-size: 28px;
  font-weight: 400;
  color: #ff0000;
  margin: auto;
  text-align: center;
  margin-top: 44px;
}
.content {
  width: 669px;
  margin: auto;
  padding-bottom: 300px;
  p {
    font-size: 25px;
    font-weight: 400;
    color: #626262;
    line-height: 35px;
    margin-top: 20px;
  }
}
</style>
