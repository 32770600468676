import { axios } from "./../plugins/axios";
/**
 * @method 下载海报图片
 */
export function download() {
  return axios.request({
    params: {
      module: "fans",
      action: "share",
      app: "share",
      url: "https://d.yhaocang.com/#/channel"
    }
  });
}
